/*!
 * AUIGrid v3.0.15 License
 * License Type : Evaluation(Trial) Version
 * Authorized Domain(or IP) : localhost,127.0.0.1,testwss.ekosco.com,wss.ekocso.com
 * Expiration Date : 2024-12-12
 * www.auisoft.net
 */
/* eslint-disable */
// var AUIGridLicense =
//   "eyJjdCI6InFzWEtiYUZKK2gxbnk3SzNVT25GTlwvbnc4NXJ0NEJJRXV0NTRMVFcraUE0RW1VR0tlOXRsdng4NVNMVUhRK1VoWTNxOG5XWlRBOXIxZlwvVkFNK2VJQ3YxQTRVblB3Z2ZyK2lMY2RCVHhCb2gyNVpLY2x1UzdZdEJhOW12TkhZT2xITnk3UDd5WkdlbkE1U2Z6a1ZJTVpRPT0iLCJpdiI6IjBjN2Q3MWM0YTYwMTI4N2UyYjZhMTRlYzQ3MmNlMWFhIiwicyI6IjA1ODYzZDE0ZjAzOTAxNTkifQ==";
// if (typeof window !== "undefined") window.AUIGridLicense = AUIGridLicense;
/*
/*!
 * AUIGrid v3.0.15 License
 * Product ID : AUIGrid-EN-10085-628982-241017
 * Authorized Domain(or IP) : wss.ekosco.com
 * www.auisoft.net
 */
/* eslint-disable */
// var AUIGridLicense =
//   "eyJjdCI6IlY5Z2JaYnZZa1ZNUmN6TkFFWURLMm5nUjVLY01VUG1lYThQRWg4UndnRGdMYU9qQTRwN1l6TlVuM01UYTE1UTdFZDhXand3elwvYm5qUXZ3WHJGaDNvcmFpUERXWFQ2dEhySFNxOXVmMnVzWmlJT1VWR3NXRDBlM2pnNWNcL1V2NjV1VkRRc0p2T2JUUmRJK1d2MnZaNFlnPT0iLCJpdiI6IjE0MjQ5NzBiY2NkZThhMTNjNDBjOGI5ZDRiYWQ3MDViIiwicyI6Ijk1MWRjZmFlMzkxMTlkMGIifQ==";
// if (typeof window !== "undefined") window.AUIGridLicense = AUIGridLicense;

/*!
 * AUIGrid v3.0.15 License
 * License Type :  Enterprise Dev License
 * Authorized Domain(or IP) : testwss.ekosco.com
 * Expiration Date :  2025-05-31
 * www.auisoft.net
 */
/* eslint-disable */
var AUIGridLicense =
  "eyJjdCI6IktyUHgrSm84Rm5HWWlqbytCaWoyaWJ3OEhONmVzNDc5RTRnRnFyQ3RFbVRtTzJONEJzU1o0ZlRXTzRwUUJEK0NaWmRscVdQclJ6UU81QzVQYUN4WDZlRkZ4dERpbllxc1YxUklRQmJzdEdCdnNRNXlJM2N1RkVGVm5Bc0Z0ZVhWIiwiaXYiOiIzYzRiZjY3YjUwMGEzNTQxNzU1ZjdlNjhiYTEyYjRiYyIsInMiOiIxNjMyNTU2YzcyYmRjODIxIn0=";
if (typeof window !== "undefined") window.AUIGridLicense = AUIGridLicense;
